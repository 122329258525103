import React, { useEffect, useRef, useState } from 'react'
import { useForm } from '../../hooks';
import { getDepartments } from '../../utils/departmentUtils/departmentUtils';
import { GrFormClose } from 'react-icons/gr';
import { AddButton, ImageInput, SelectInput, ShowImage, TextAreaInput, TextInput } from '../Common/FormComponents';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { addBlog } from '../../utils/blogsUtils/blogsUtils';
import { validateBlogForm } from '../../utils/blogsUtils/validateBlogFrom';
import JoditEditor,{Jodit} from 'jodit-react';

const config = {
  readonly: false,
  placeholder: "Content",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const BlogsModal = ({ setOpen, editData = null, setEditData, setBlogsFn }) => {
  const [departments, setDepartments] = useState([]);
  const initialState = {
    blog_title: editData?.blog_title || "",
    blog_category: editData?.blog_category?.department || "Select Department",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
  };
  const editor = useRef(null);
  const [description, setDescription] = useState(editData?.blog_description || "<p></p>");
  const metaTagInitialState = { tag: "" };
  const [metaFormData, handleMetaForm, clearMetaForm] = useForm(metaTagInitialState);
  const blogFaqInitialState = {  blog_faq_question: "", blog_faq_answer:"" };
  const [blogFaqFormData, handleBlogFaqFromData, clearBlogFaqForm] = useForm(blogFaqInitialState);
  const faqInitialState = {  faq_question: "", faq_answer:"" };
  const [faqFormData, handleFaqFromData, clearFaqForm] = useForm(faqInitialState);
  const [blogs_faq, setBlogFaqs] = useState(editData?.blogs_faq || []);
  const [faq, setFaqs] = useState(editData?.faq || []);
  const handleFaqs = (action, idx = 0) => {
    if (action === "add") {
      if (
        !faqFormData.faq_question.trim().length ||
        !faqFormData.faq_answer.trim().length
      ) {
        return setError({ faq: "Please Enter Question & Answer" });
      } else {
        setFaqs([
          ...faq,
          {
            faq_question: faqFormData.faq_question,
            faq_answer: faqFormData.faq_answer,
          },
        ]);
        clearFaqForm();
      }
    } else if (action === "remove") {
      setFaqs(faq.filter((_, index) => index !== idx));
    }
  };
  const handleBlogFaqs = (action, idx = 0) => {
    if (action === "add") {
      if (
        !blogFaqFormData.blog_faq_question.trim().length ||
        !blogFaqFormData.blog_faq_answer.trim().length
      ) {
        return setError({ blogFaq: "Please Enter Question & Answer" });
      } else {
        setBlogFaqs([
          ...blogs_faq,
          {
            blog_faq_question: blogFaqFormData.blog_faq_question,
            blog_faq_answer: blogFaqFormData.blog_faq_answer,
          },
        ]);
        clearBlogFaqForm();
      }
    } else if (action === "remove") {
      setBlogFaqs(blogs_faq.filter((_, index) => index !== idx));
    }
  };
  const [err, setError] = useState({ global: "", blogFaq: ""});
  const [metaTags, setMetaTags] = useState(editData?.meta_tag || []);
  const handleMetaTags = (action, idx = 0) => {
    if (action === "add") {
      if (!metaFormData.tag.trim().length) {
        return setError({ global: "Please Enter Tag" });
      } else {
        setMetaTags([...metaTags, metaFormData.tag]);
        clearMetaForm();
      }
    } else if (action === "remove") {
      setMetaTags(metaTags.filter((_, index) => index !== idx));
    }
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [deps, setDeps] = useState([]);
  const [dpId, setDpId] = useState(0);
  useEffect(() => {
    getDepartments().then((res) => {
      setDepartments([{ department: "Select Department", _id: 0 }, ...res.data.data]);
      setDeps(["Select Department", ...res.data.data.map((item) => item.department)]);
    }).catch(err => {
      setDepartments([{ department: "Select Department", _id: 0 }]);
    })
  }, []);
  useEffect(() => {
    const foundDepartment = departments.find(
      (dep) => dep.department === formData.blog_category
    );
    if (foundDepartment) {
      setDpId(foundDepartment._id);
    }
  }, [departments, formData.blog_category]);
  const [loading, setLoading] = useState(false);
  const [blog_image, setBlogImage] = useState(editData?.blog_image || null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateBlogForm(formData,description,dpId,setError)) return;
    setError({ global: "" , blogFaq:"", faq:"" });
    setLoading(true);
    addBlog(formData,description,dpId,blogs_faq,faq,metaTags,blog_image,editData?._id)
      .then((res) => {
          clearForm();
          setBlogImage(null);
          toast(`Blog ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
        setEditData(null);
        setBlogsFn();
        setOpen(false);
      })
      .catch((err) => {
        toast(`Blog ${editData ? "edit" : "add"} failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => { setLoading(false) });
  };
  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <span>{editData ? "Edit Blog" : "Add New Blog"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Title"}
            formName={"blog_title"}
            setError={setError}
          />
          <SelectInput
            data={formData}
            handleInput={handleInput}
            items={deps}
            name={"Department"}
            formName={"blog_category"}
            setError={setError}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Content"} className="label">
            {"Content"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={description}
            config={config}
            tabIndex={1}
            onBlur={(newContent) => setDescription(newContent)}
          />
        </div>
        <div className="feild-conatainer">
          {
            <>
              <ShowImage image={blog_image} setImage={setBlogImage} />
              <ImageInput
                image={blog_image}
                setImage={setBlogImage}
                imageName={"Blog Image"}
              />
            </>
          }
        </div>
        {blogs_faq?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {blogs_faq?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item?.blog_faq_question}</span>
                <button
                  onClick={() => {
                    handleBlogFaqs("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer flex-col">
          <TextInput
            data={blogFaqFormData}
            handleInput={handleBlogFaqFromData}
            name={"Blog Question"}
            formName={"blog_faq_question"}
            setError={setError}
          />
          <div className="flex">
            <TextAreaInput
              data={blogFaqFormData}
              handleInput={handleBlogFaqFromData}
              name={"Blog Answer"}
              formName={"blog_faq_answer"}
              setError={setError}
            />
            <AddButton handleAdd={handleBlogFaqs} />
          </div>
        </div>
        {err.blogFaq && <div className="error-message">{err.blogFaq}</div>}
        {faq?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {faq?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item?.faq_question}</span>
                <button
                  onClick={() => {
                    handleFaqs("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer flex-col">
          <TextInput
            data={faqFormData}
            handleInput={handleFaqFromData}
            name={"Question"}
            formName={"faq_question"}
            setError={setError}
          />
          <div className="flex">
            <TextAreaInput
              data={faqFormData}
              handleInput={handleFaqFromData}
              name={"Answer"}
              formName={"faq_answer"}
              setError={setError}
            />
            <AddButton handleAdd={handleFaqs} />
          </div>
        </div>
        {err.faq && <div className="error-message">{err.faq}</div>}
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Name"}
            formName={"meta_name"}
            setError={setError}
          />
          <TextAreaInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Description"}
            formName={"meta_description"}
            setError={setError}
          />
        </div>
        {metaTags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {metaTags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleMetaTags("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={metaFormData}
            handleInput={handleMetaForm}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setError}
          />
          <AddButton handleAdd={handleMetaTags} />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogsModal