import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NoDataFound, SearchInput, Table } from "../Common";
import { FaFileDownload } from "react-icons/fa";
import DateInput from "../Common/FormComponents/DateInput/DateInput";
import * as XLSX from "xlsx";
import { useForm } from "../../hooks";
import { getAllPatients } from "../../utils/adminUtils/adminUtils";

const PatientsDataSection = () => {
  const headings = useMemo(() => ["Name", "P-ID", "V-ID", "Contact"], []);
  const [patients, setPatietns] = useState([]);
  const setAllPatientsFn = useCallback(() => {
    getAllPatients()
        .then((res) => {setPatietns(res.data.data.ReceptionAppointment) })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => setAllPatientsFn(), [setAllPatientsFn]);
  const setError = useState({ global: "" })[1];
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [dateData, handleDateData, clearDateData] = useForm({
    start: "",
    end: "",
  });
  useEffect(() => {
    const filteredData = patients?.filter((patient) => {
      const nameMatch = patient?.patient_id?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const pIdMatch = patient?.patient_id?.patient_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const vIdMatch = patient?.visit_id
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const contactMatch = patient?.patient_id?.phone_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const dateMatch = () => {
        if (dateData.start && dateData.end) {
          const dateObject = new Date(patient.createdAt)
            .toLocaleString()
            .split(",")[0];
          const startDateMatch =
            Date.parse(dateData.start) <= Date.parse(dateObject);
          const endDateMatch =
            Date.parse(dateData.end) >= Date.parse(dateObject);
          return startDateMatch && endDateMatch;
        }
        return true;
      };
      return (
        dateMatch() &&
        (nameMatch || pIdMatch || vIdMatch || contactMatch)
      );
    });
    setFilteredPatients(filteredData);
  }, [patients, searchQuery, dateData]);
  const exportToXLSX = (data, headings) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Patients Data");
    XLSX.writeFile(workbook, "PatientsData.xlsx");
  };
  return (
    <div className="h-full  w-full mt-3">
      <div className="flex w-full justify-center flex-col items-center mb-5 gap-2">
        <div className="flex w-full sm:w-1/2">
          <SearchInput
            placeholder={"Search by Name, Id, Contact"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <div className="flex w-full justify-between flex-col sm:flex-row gap-3">
          <div className="flex  gap-2 items-end ">
            <DateInput
              name="Start"
              data={dateData}
              formName={"start"}
              handleInput={handleDateData}
              setError={setError}
            />
            <DateInput
              name="End"
              data={dateData}
              formName={"end"}
              handleInput={handleDateData}
              setError={setError}
            />
            {(dateData.start.length > 0 || dateData.end.length > 0) && (
              <div className="flex w-fit h-fit  mb-2 bg-slate-300 rounded">
                <button
                  onClick={() => clearDateData()}
                  className="text-xs translate-x-1 -translate-y-1 hover:translate-x-0 hover:translate-y-0 bg-white py-0.5 px-1 rounded  duration-500 text-primary border"
                >
                  Clear
                </button>
              </div>
            )}
          </div>
          <div
            className={`${
              filteredPatients?.length > 0 ? "flex" : "hidden"
            } gap-2 items-end `}
          >
            <button
              className="text-white bg-primary text-base rounded-md px-3 py-1 h-fit flex gap-1 items-center"
              onClick={() => exportToXLSX(filteredPatients, headings)}
            >
              Download <FaFileDownload />
            </button>
          </div>
        </div>
      </div>
      {filteredPatients?.length > 0 ? (
        <Table headings={headings}>
          {filteredPatients?.map((patient, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {patient?.patient_id?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {patient?.patient_id?.patient_id}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {patient?.visit_id}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {patient?.patient_id?.phone_number}
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"patients"} />
      )}
    </div>
  );
};

export default PatientsDataSection;
