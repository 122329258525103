import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { TbUserEdit } from "react-icons/tb";
import { NoDataFound } from "../Common";
import { deleteLab } from "../../utils/labUtils/labUtils";

const LabsTable = ({ setOpen, setEditData, labs, setLabsFn, user }) => {
  const tableHeadings = useMemo(() => {
    const defaultHeadings = ["Name", "Owner", "Email", "Phone"];
    const userHasEditAccess = user.access.includes("Edit");
    const userHasDeleteAccess = user.access.includes("Delete");
    const headings = [...defaultHeadings];
    if (userHasEditAccess) {
      headings.push("Edit");
    }
    if (userHasDeleteAccess) {
      headings.push("Delete");
    }
    return headings;
  }, [user]);
  const delLab = (labId) => {
    deleteLab(labId)
      .then((res) => setLabsFn())
      .catch((err) => console.log(err));
  };
  return (
    <div className="flex h-fit items-center w-full flex-col mt-5">
      <div className="flex flex-col w-full mt-3 max-h-[400px]">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {labs.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th
                          scope="col"
                          key={index}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-[400px] ">
                    {labs?.map((lab, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center gap-4">
                            <div className="flex-shrink-0 h-9 w-9 justify-center items-center border rounded-full flex">
                              <img
                                className="rounded-full h-9 w-9 object-cover"
                                src={lab?.lab_profile_image}
                                alt="lab_profile"
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-900">
                              {lab?.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {lab?.owner_name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 ">
                            {lab?.email_id.slice(0, 10) + "..."}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {lab?.phone_number}
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Edit") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              setEditData(lab);
                              setOpen(true);
                            }}
                          >
                            <TbUserEdit />
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Delete") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              delLab(lab?._id);
                            }}
                          >
                            <MdOutlineDeleteOutline color="red" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={" labs "} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabsTable;
