import React, { useState } from 'react'
import { BsThreeDotsVertical } from "react-icons/bs";
import { deleteCoupon } from '../../../utils/couponUtils/couponUtils';

const YellowCoupon = ({ data, setEditData, setModalOpen, setCouponsFn, user }) => {
  const [open, setOpen] = useState(false);
  const delCoupon = (couponId) => {
    deleteCoupon(couponId).then(res => {
      setCouponsFn();
    }).catch(err => { console.log(err) });
  }
  return (
    <div className="flex w-full  h-[200px]  bg-[#FFEEBA] rounded-md shadow-md relative">
      <button
        disabled={
          !user.access.includes("Edit") && !user.access.includes("Delete")
        }
        onClick={() => {
          setOpen(!open);
        }}
        className={`${
          !user.access.includes("Edit") && !user.access.includes("Delete")
            ? "hidden"
            : "flex"
        }  absolute top-2 right-2 z-10`}
      >
        <BsThreeDotsVertical color="black" fontSize={18} />
      </button>
      {open && (
        <div className="flex overflow-hidden bg-white font-semibold w-fit h-fit  rounded-md absolute top-4 right-7 z-10 shadow-md flex-col text-xs">
          <button
            onClick={() => {
              setEditData(data);
              setModalOpen(true);
            }}
            className="flex cursor-pointer w-full h-full border-b  justify-center px-3 py-1 items-center hover:bg-teal-50"
          >
            edit
          </button>
          <button
            disabled={!user.access.includes("Edit")}
            className={`${
              !user.access.includes("Edit") ? "hidden" : "flex"
            } w-full h-full ${
              data?.status ? "text-red-600" : "text-green-400"
            }  hover:bg-teal-50 cursor-pointer  border-b  justify-center items-center px-3 py-1`}
          >
            {data?.status ? "de-activate" : "activate"}
          </button>
          <button
            disabled={!user.access.includes("Delete")}
            onClick={() => {
              delCoupon(data?._id);
            }}
            className={`${
              !user.access.includes("Delete") ? "hidden" : "flex"
            } w-full cursor-pointer h-full justify-center items-center px-3 py-1    hover:bg-teal-50 text-red-600`}
          >
            delete
          </button>
        </div>
      )}
      <div className="flex  w-full">
        <div className="flex w-full  h-full flex-col">
          <div className="flex rounded-bl-3xl ml-5 sm:ml-14 rounded-br-3xl w-[130px] h-[70px] bg-[#FFAF3C] p-1">
          </div>
          <div className="flex w-full h-full  flex-col ml-5 mt-2 sm:ml-14   ">
            <div className="flex text-sm text-primary font-semibold uppercase ">
              welcome
            </div>
            <div className="flex text-4xl xl:text-5xl text-[#FFAF3C] font-black uppercase mb-1">
              voucher
            </div>
            <div className="flex text-sm text-primary font-semibold capitalize">
              don't miss the discount
            </div>
            <div className="flex text-sm text-primary font-semibold capitalize">
              {data?.coupon_date}
            </div>
          </div>
        </div>
        <div className="flex w-[200px] h-full p-5 ">
          <div className="flex w-[200px] h-full rounded-full  text-[#FFAF3C] border-8 text-5xl font-black items-center justify-center border-[#FFAF3C]">
            {data?.coupon_percentage}%
          </div>
        </div>
      </div>
      <div className="flex w-[40px] h-full relative  font-semibold border border-dashed border-t-0 border-l-2 border-r-0 border-b-0 border-primary ">
        <span
          className=" w-0 h-0  absolute  left-1.5 bottom-12 "
          style={{ transform: "rotate(-90deg)" }}
        >
          {data?.coupon_code}
        </span>
      </div>
    </div>
  );
};

export default YellowCoupon