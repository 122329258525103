import { axiosAuthorized } from "../../api/config"

export const getBlogs = async () => {
    try {
        const response = await axiosAuthorized.get("/blog/get_blog");
        return response;
    } catch (error) {
        throw error;
    }
}


export const addBlog = async (initialState, blog_description, blog_category, blogs_faq,faq, meta_tag, blog_image, blogId = null) => {
    try {
        const requestData = {
            blog_title: initialState.blog_title,
            blog_category ,
            blog_image,
            blog_description,
            blogs_faq,
            faq,
            meta_name: initialState.meta_name,
            meta_description: initialState.meta_description,
            meta_tag
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = blogId
            ? `/blog/update_blog/${blogId}`
            : `/blog/create_blog`;

        const response = await axiosAuthorized[blogId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteBlog = async (blogId) => {
    try {
        const response = await axiosAuthorized.delete(`/blog/delete_blog/${blogId}`);
        return response;
    } catch (error) {
        throw error;
    }
}  